export type ErrorProps = {
  statusCode: number
  title: string
  subheader: string
  buttonText: string
}

export const defaultErrorProps: Omit<ErrorProps, 'statusCode'> = {
  title: 'Hovsa! Noget gik galt...',
  subheader:
    'Siden, du leder efter, er desværre ikke lige i øjet. Vi beklager og kan ikke vise den nu.',
  buttonText: 'Gå til forsiden',
}
